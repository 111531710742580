/**
 * Formats or removes formatting of a Brazilian ZIP code.
 *
 * @param {string} zipcode - The string containing the ZIP code, which may or may not be formatted.
 * @param {object} options - Options for formatting.
 * @param {boolean} options.mask - If true, formats the ZIP code; if false, removes formatting.
 * @returns {string} - Returns the formatted or cleaned ZIP code based on the options provided, or an empty string if the input is invalid.
 */
export default (zipcode: string = '', options: { mask: boolean }): string => {
  const zip = zipcode.replace(/\D/g, '')

  if (typeof options.mask !== 'boolean' || !zip || zip.length !== 8)
    return ''

  if (options.mask) {
    return zip.replace(/(\d{5})(\d{3})/, '$1-$2')
  }

  return zip
}
